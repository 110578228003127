import { Layout, Menu } from "antd";
import type { ReactNode } from "react";
import { Kpis } from "./Kpis";

type FullScreenLayoutProps = {
	children: ReactNode;
};

export const FullScreenLayout = ({ children }: FullScreenLayoutProps) => {
	return (
		<Layout
			style={{
				height: "100vh",
				display: "grid",
				gridTemplateRows: "auto 1fr auto",
			}}
		>
			<Layout.Header
				style={{
					height: "40px",
					lineHeight: "40px",
					paddingInline: "10px",
				}}
			>
				<Menu
					theme="dark"
					mode="horizontal"
					selectable={false}
					items={[
						{
							key: "1",
							label: <Kpis />,
						},
					]}
				/>
			</Layout.Header>
			<Layout.Content
				style={{
					overflow: "auto",
					padding: "10px",
				}}
			>
				{children}
			</Layout.Content>
			<Layout.Footer
				style={{
					backgroundColor: "#001529",
					color: "#fff",
					textAlign: "end",
					padding: "10px",
				}}
			>
				Celerum ©2025
			</Layout.Footer>
		</Layout>
	);
};
